









































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import snakeCaseKeys from 'snakecase-keys'

const sessionModule = namespace('session')

@Component
export default class EventBuyTicketsAccess extends Vue {
  @sessionModule.Action('login') loginAction!: Function
  @sessionModule.Getter state!: Record<string, string>
  @sessionModule.Getter isNewSelfSubscriber!: boolean
  @sessionModule.Getter isPublicBuyer!: boolean
  @sessionModule.Getter getSubscription!: Record<string, string>

  form = {
    email: '',
    password: '',
    isPublicBuyer: true,
    eventId: ''
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      Object.assign(this.form, {
        email: 'presidenteevento@indicativa.com.br',
        password: 'senha123'
      })
    }
    this.form.eventId = this.$route.params.eventId
  }

  submitLogin () {
    this.loginAction(snakeCaseKeys(this.form, { deep: true }))
      .then(() => {
        if (this.state.success) {
          const redirect = this.$route.query.redirect as string

          if (redirect !== undefined) {
            this.$router.push(redirect)
          } else {
            const { eventId, subscriptionId } = this.getSubscription
            if (this.isNewSelfSubscriber) {
              this.$router.push({
                name: 'EventVoucherFinishAndPrint',
                params: {
                  eventId,
                  subscriptionId
                }
              })
            } else if (this.isPublicBuyer) {
              this.$router.push({ name: 'EventBuyTickets', params: { id: eventId } })
            } else {
              this.$router.push({ name: 'Home' })
            }
          }
        }
      })
  }
}
